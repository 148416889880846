import React, { useState } from "react";
import { Button, Checkbox, Table, Space, notification } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const UpdateAttendanceteacher = () => {
  const [endTime, setEndTime] = useState(null);

  const columns = [
    {
      title: "S. No.",
      dataIndex: "index",
      key: "index",

      render: (_, __, index) => index + 1,
    },
    {
      title: "Grade",
      dataIndex: "name",
    },
    {
      title: "Section",
      dataIndex: "name",
    },

    {
      title: "Lecture Time",
      dataIndex: "name",
    },
    {
      title: "Subject",
      dataIndex: "name",
    },
    {
      title: "Attendance Status",
      dataIndex: "name",
    },
    {
      title: "Check Strength",
      dataIndex: "name",
    },
    {
      title: "Present",
      dataIndex: "name",
    },
    {
      title: "Absent",
      dataIndex: "name",
    },
    {
      title: "Action",
      dataIndex: "selected",
      render: (_, record) => (
        <Space>
          {record?.status !== "pending" ? (
            <>
              {/* <Button variant="outlined" onClick={()=>navigate("/teacher/attendance/edit")} >Edit</Button> */}
              <Button danger variant="outlined">
                Withdraw
              </Button>
            </>
          ) : (
            <Button variant="outlined">View</Button>
          )}
        </Space>
      ),
    },
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Update Past Attendance</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <DatePicker
                    selected={endTime}
                    onChange={(update) => {
                      setEndTime(update);
                    }}
                    isClearable={true}
                    placeholderText="Select Date "
                    dateFormat="dd-MM-yyyy"
                  />
                </div>

                <div className="mb-3 me-2">
                <button
                  onClick={() => {
                    setEndTime(null);
                  }}
                  className="btn btn-primary filter_btn"
                >
                  Reset Filter
                </button>
              </div>
              </div>

             
            </div>

            <div className="card-body p-0 py-3">
              <Table
                rowKey="_id"
                columns={columns}
                className="bordered-table"
                pagination={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateAttendanceteacher;

import React from 'react'
import { Button, Checkbox, Table, Space, notification } from "antd";

const TodayAttendanceteacher = () => {

    const columns = [
        {
          title: "S. No.",
          dataIndex: "index",
              key: "index",
             
          render: (_, __, index) => index + 1, // Calculates serial number
        },
        {
          title: "Grade",
          dataIndex: "name",
          },
          {
            title: "Section",
            dataIndex: "name",
          },
    
          {
            title: "Lecture Time",
            dataIndex: "name",
          },
          {
            title: "Subject",
            dataIndex: "name",
          },
          {
            title: "Attendance Status",
            dataIndex: "name",
          },
          {
            title: "Check Strength",
            dataIndex: "name",
          },
          {
            title: "Present",
            dataIndex: "name",
          },
          {
            title: "Absent",
            dataIndex: "name",
          },
        {
          title: "Action",
          dataIndex: "selected",
          render : (_, record) => (
            <Space>
            {record?.status !== "pending" ? (
              <>
                {/* <Button variant="outlined" onClick={()=>navigate("/teacher/attendance/edit")} >Edit</Button> */}
                <Button danger variant="outlined">Withdraw</Button>
              </>
            ) : (
              <Button   variant="outlined">View</Button>
            )}
          </Space>)
        },
      ];
  return (
      <div>
          
          <div className="page-wrapper">
          <div className="content">

      
          <div>
                   <h3 className="text-dark text-center" style={{fontSize:"x-large"}}>Today's Attendance</h3>
        </div>
 <div className='card mt-5'>
 <Table
            rowKey="_id"
            columns={columns}
            className="bordered-table"
            pagination={false}
          />
 </div>
     

              </div>
</div>
              

       
    </div>
  )
}

export default TodayAttendanceteacher
import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import { authRoutes, publicRoutes, studentRoute } from "./router.link";
import Feature from "../feature";
import AuthFeature from "../authFeature";
import Login from "../auth/login/login";
import Loginteacher from "../auth/login/login-2";
import { AuthContext } from "../helper/AuthState";
import PublicRoute from "../../PublicRoute";
import ProtectedRoute from "../../ProtectedRoute";
import AssignPoint from "../mainMenu/studentDashboard/Behaviour/Assign/AssignPoint";
// import "./style/css/style.css";
// import "../../style/css/style.css"
import { all_routes } from "./all_routes";

const ALLRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [authState, setAuthState] = useState({
    role: "",
    name: "",
    class: "",
    id: "",
    fatherName: "",
    startYear: "",
    startYearId: "",
    academiclenth: "",
  });
  const jwtToken = localStorage.getItem("accessToken");
  const userType = localStorage.getItem("user");


  return (
    <>
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route
              path="student/login/:schoolName/:uniqueId"
              element={<Login />}
            />
            <Route
              path="teacher/login/:schoolName/:uniqueId"
              element={<Loginteacher />}
            />
          </Route>

          <Route element={<ProtectedRoute />}>
            <Route element={<Feature />}>
              {publicRoutes.map((route, idx) => (
                <Route path={route.path} element={route.element} key={idx} />
              ))}
            </Route>
          </Route>
          

        
          <Route element={<ProtectedRoute />}>
            <Route element={<Feature />}>
              {studentRoute.map((route, idx) => (
                <Route path={route.path} element={route.element} key={idx} />
              ))}
            </Route>
          </Route>


          
         
        </Routes>
      </AuthContext.Provider>
    </>
  );
};

export default ALLRoutes;

import React, { useContext, useEffect, useRef, useState } from "react";
import { teacherAttendance } from "../../../../core/data/json/teacher_attendance";
import { TableData } from "../../../../core/data/interface";
import { Link, useNavigate } from "react-router-dom";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import PredefinedDateRanges from "../../../../core/common/datePicker";
import { notification, Table } from "antd";
import CommonSelect from "../../../../core/common/commonSelect";
import {
  attendance,
  studentclass,
  studentName,
  teacherId,
} from "../../../../core/common/selectoption/selectoption";
import TooltipOption from "../../../../core/common/tooltipOption";
import { all_routes } from "../../../router/all_routes";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
import { AuthContext } from "../../../helper/AuthState";
const AttendanceListing = () => {
  const [collecIds, setcollecIds] = useState([]);
  const { authState } = useContext(AuthContext);
  const academicYearId = localStorage.getItem("academicYearId");

  //   filter states
  const [search, setSearch] = useState("");
  const [dateRange, setdateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  //   pagination

  const navigate = useNavigate();
  const [dayTag, setDayTag] = useState("");
  const [allTag, setallTag] = useState([]);
  const [loading, setLoading] = useState(false);

  //new states

  const [dayType, setDayType] = useState("");
  const [AttendanceTag, setAttendanceTag] = useState("");

  const [Tag, setTag] = useState("");

  const [alldayTagText, setallDayTagText] = useState("");
  const redirectto = () => {
    navigate("/student/attendance/certificates");
  };

  const columns = [
    {
      title: "S.No",
      key: "sno",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",

      // render: (_, render) => moment(render?.date).format("DD-MM-YYYY"),
      sorter: (a, b) => {
        // Compare the raw dates (before formatting)
        const dateA = moment(a.date);
        const dateB = moment(b.date);

        // Return comparison for sorting (ascending or descending)
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
    },
    {
      title: "Day Type",
      dataIndex: "day_type",
      render: (_, render) => render?.day_type?.type,
    },

    {
      title: "Scan Source",
      dataIndex: "scan_source",
      // Access nested field `firstName`
    },
    {
      title: "TimeStamp",
      dataIndex: "time",

      // sorter: (a, b) => a.user_type.localeCompare(b.user_type),
    },
    {
      title: "Attendance Tag",
      dataIndex: "tag",
      render: (_, render) => render?.tag?.tag,

      // sorter: (a, b) => a.user_type.localeCompare(b.user_type),
    },
    {
      title: "Action",

      render: (_, record) => (
      
          <button
            className=" btn btn-outline-success"
            onClick={() => navigate("")}
          >
            {" "}
            View{" "}
          </button>
        
      ),
    },
  ];

  const getAssignPoints = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_DEV_BASE_URL
        }/api/v1/attendance/user-attendance/${localStorage.getItem(
          "userId"
        )}?search=${search}&startDate=${
          startDate ? moment(startDate).format("YYYY-MM-DD") : ""
        }&endDate=${
          endDate ? moment(endDate).format("YYYY-MM-DD") : ""
        }&tagId=${Tag}&dayTypeId=${dayTag}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      setPointsList(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getDayTag = async () => {
     setLoading(true); // Start loading state for the button

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/attendance/day-type`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      setallDayTagText(res?.data?.data?.docs);

      if (res.data && res.data.dayTag) {
      } else {
        console.warn("Unexpected response structure:", res);
      }
    } catch (error) {
      console.error("Error fetching day type:", error.message || error);
    } finally {
      setLoading(false);
    }
  };

  const getTag = async () => {
     setLoading(true); // Start loading state for the button

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/attendance/tags`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      setallTag(res?.data?.data?.docs);
    } catch (error) {
      console.error("Error fetching day type:", error.message || error);
    } finally {
      setLoading(false); // Stop loading state regardless of success or error
    }
  };
  useEffect(() => {
    getDayTag();
    getTag();
  }, []);

  useEffect(() => {
    if (startDate) {
      if (endDate) {
        getAssignPoints();
      }
    } else {
      getAssignPoints();
    }
  }, [search, dateRange, dayTag, Tag]);
  const [pointsList, setPointsList] = useState([]);

  const token = localStorage.getItem("accessToken");

  const routes = all_routes;
  const data = teacherAttendance;
  const [leaveType, setLeaveType] = useState("");
  const [leaveList, setleaveList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [filterStatus, setFilterStatus] = useState("");

  const [selectedOptions, setSelectedOptions] = useState(
    data.map(() => "Present") // Default to 'Present' for each row
  );
  const dropdownMenuRef = useRef(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const getLeaveRequest = async () => {
    try {
      const res = await axios.get(
        `${
          process.env.REACT_APP_DEV_BASE_URL
        }/api/v1/attendance/leave-request/user/${localStorage.getItem(
          "userId"
        )}?leaveType=${leaveType}&status=${filterStatus}&startDate=${
          startDate ? moment(startDate).format("YYYY-MM-DD") : ""
        }&endDate=${endDate ? moment(endDate).format("YYYY-MM-DD") : ""}&academicYearId=${
              academicYearId == authState?.startYearId
                ? academicYearId
                : authState?.startYearId
            }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setleaveList(res?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (startDate) {
      if (endDate) {
        getLeaveRequest();
      }
    } else {
      getLeaveRequest();
    }
  }, [dateRange, filterStatus, leaveType]);
  // Handle state change for each row
  const handleOptionChange = (index, value) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = value;
    setSelectedOptions(newSelectedOptions);
  };

  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-flex custom-responsive align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1" style={{ fontSize: "x-large" }}>
                  Attendence
                </h3>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                <div className="mb-2">
                  <Link className="btn btn-primary">
                    <i class="ti ti-file-certificate me-2"></i>
                    View Certificate
                  </Link>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* Teacher Attendence List */}
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                <h4 className="mb-3">Attendence List</h4>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="input-icon-start mb-3 me-2 position-relative notification-box">
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setdateRange(update);
                      }}
                      dateFormat="dd-MM-yyyy"
                      isClearable={true}
                      placeholderText="Select Date Range"
                    />
                                  </div>
                                  <div className="input-icon-start mb-3 me-2 position-relative notification-box">
                                  <input
            style={{paddingLeft:"12px"}}
            className="form-control"
            type="text"
            placeholder="Search by keyword"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />                                   </div>
                                
                  <div className="dropdown mb-3 me-2">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      <i className="ti ti-filter me-2" />
                      Filter
                    </Link>
                    <div
                      className="dropdown-menu drop-width"
                      ref={dropdownMenuRef}
                    >
                      <form>
                        <div className="d-flex align-items-center border-bottom p-3">
                          <h4>Filter</h4>
                        </div>
                        <div className="p-3 border-bottom">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Day Type</label>
                                <CommonSelect
                                  className="select"
                                  options={[
                                    { value: "", label: "Select Day Type" },
                                    ...(alldayTagText
                                      ? alldayTagText.map((data) => ({
                                          value: data?._id,
                                          label: data?.type,
                                        }))
                                      : []),
                                  ]}
                                  value={
                                    dayTag
                                      ? {
                                          value: dayTag,
                                          label:
                                            alldayTagText?.find(
                                              (item) => item._id === dayTag
                                            )?.type || dayTag,
                                        }
                                      : { value: "", label: "Select Day Type" }
                                  }
                                  onChange={(e) => setDayTag(e ? e.value : "")}
                                />

                               
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Attendance Tag</label>

                                <CommonSelect
  className="select"
  options={[
    { value: "", label: "Attendance Tag" },
    ...(allTag
      ? allTag.map((data) => ({
          value: data?._id,
          label: data?.tag,
        }))
      : []),
  ]}
  value={
    Tag
      ? { value: Tag, label: allTag?.find(item => item._id === Tag)?.tag || Tag }
      : { value: "", label: "Attendance Tag" }
  }
  onChange={(e) => setTag(e ? e.value : "")}
/>

                              </div>
                            </div>

                          
                          </div>
                        </div>
                        <div className="p-3 d-flex align-items-center justify-content-end">
                                                  <Link to="#" className="btn btn-light me-3" onClick={() => {
                                                      setdateRange([null, null])
                                                      setTag({ value: "", label: "Attendance Tag" })
                                                      setDayTag({ value: "", label: "Select Day Type" })
                                                      setSearch("")
                          }}>
                            Reset
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={handleApplyClick}
                          >
                            Apply
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                
                </div>
              </div>
              <div className="card-body p-0 py-3">
              
                <Table
                  columns={columns}
                  Selection={true}
                />
                
              </div>
            </div>
           
          </div>
        </div>
       
      </>
    </div>
  );
};

export default AttendanceListing;

import { useEffect, useRef, useState } from "react";
import { teacherAttendance } from "../../../../core/data/json/teacher_attendance";
import { Link, useNavigate } from "react-router-dom";

import { Checkbox, Table } from "antd";
import CommonSelect from "../../../../core/common/commonSelect";

import { all_routes } from "../../../router/all_routes";
import { TimePicker } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
import {
  CModal,  CButton,

  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
const AttendanceListingteacher = () => {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [dateRange, setdateRange] = useState([null, null]);
  //   pagination

  const navigate = useNavigate();
  const [dayTag, setDayTag] = useState("");
  const [allTag, setallTag] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);

  //new states
  const [selectedUsers, setSelectedUsers] = useState();
  const [collecIds, setCollecIds] = useState([]);
  const [subject, setSubject] = useState("");

  const [dayType, setDayType] = useState("");
  const [AttendanceTag, setAttendanceTag] = useState("");

  const [Tag, setTag] = useState("");

  const [alldayTagText, setallDayTagText] = useState("");
  const redirectto = () => {
    navigate("/student/attendance/certificates");
  };
  const handleSelect = (id) => {
    const updatedStudents = selectedUsers.map((student) => {
      if (student._id === id) {
        student.selected = !student.selected;
        if (student.selected) {
          setCollecIds((prev) => [...prev, id]);
        } else {
          setCollecIds((prev) => prev.filter((studentId) => studentId !== id));
        }
      }
      return student;
    });
    setSelectedUsers(updatedStudents);
  };
  const columns = [
    {
      title: "S.No.",
      dataIndex: "index",
      key: "index",
      width: 0,
      render: (_, __, index) => index + 1, // Calculates serial number
    },
    {
      title: "Student Name",
      width: 100,
      dataIndex: "name",
    },
    {
      title: "Check",
      dataIndex: "selected",
      width: 50,
      render: (_, record) => (
        <Checkbox
          checked={record?.selected}
          onChange={() => handleSelect(record?._id)}
        />
      ),
    },
  ];

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  

  return (
    <div>
      <>
        
      <CModal
          backdrop="static"
          visible={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="StaticBackdropExampleLabel"
        >
          <CModalHeader>
            <CModalTitle id="StaticBackdropExampleLabel">
              Form Submission
            </CModalTitle>
          </CModalHeader>
          <CModalBody className="pb-3">
          Are you sure you want to mark the attendance as present?
          </CModalBody>
          <CModalFooter className="pl-0">
            <CButton color="secondary" onClick={() => setVisible(false)}>
              Close
            </CButton>
            <CButton
              color="primary"
              className="ms-2"
              //   onClick={() => handleAssignPoints()}
            >
              Yes!
            </CButton>
          </CModalFooter>
        </CModal>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="text-dark" style={{fontSize:"x-large"}}>
                Mark Attendance For Current Lecture
                </h3>
              </div>
              <div className="button-box">
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                <div className="mb-2 big-button">
                  <Link className="btn btn-primary big-button" to={all_routes.teacher.TodayAttendance} >
                  <i class="ti ti-calendar-search me-2"></i>
                  View Today Attendance
                  </Link>
                </div>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                <div className="mb-2 big-button">
                  <Link className="btn btn-primary big-button " to={all_routes.teacher.UpdateTodayAttendance}>
                  <i class="ti ti-edit me-2"></i>

                    Update past Today Attendance
                  </Link>
                </div>
              </div>
              </div>
              
            </div>
            <div className="attendance-box">
              <div className="d-flex flex-column justify-content-between">
                <div>
                   
                <button className="btn btn-success-light big-button" onClick={() => setVisible(true)}>Mark Own Attendance</button>
                </div>
                
                <div className="card p-3 desktop-show">
                  <div className="d-flex gap-2">
                  <DatePicker
              selected={startDate}
              onChange={(update) => {
                setStartDate(update);
              }}
              isClearable={true}
              placeholderText="Select Date "
              dateFormat="dd-MM-yyyy"
            />

            <TimePicker
              style={{ width: "200px" }}
              className="form-control "
              onChange={(value) => setStartTime(value)}
              placeholder="Select Start Time"
              isClearable={true}
              format="HH:mm"
            />

            <TimePicker
              style={{ width: "200px" }}
              className="form-control "
              onChange={(value) => setEndTime(value)}
              placeholder="Select End Time"
              isClearable={true}
              format="HH:mm"
            />

            <select
              name=""
              style={{ width: "200px" }}
              className="form-control form-select"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              id=""
            >
              <option value="">Subject</option>
            </select>
                   </div>
              
                </div>
              </div>
              <div className="card p-2 d-flex flex-column gap-2 justify-content-center">
                <h5 className="text-center">Assistent teacher</h5>
                <div className="d-flex justify-content-evenly align-items-center">
                  <span className="ml-2">Teacher 1</span>
                  <button  className="btn btn-success-light" onClick={()=>setVisible(true)}>Mark present</button>
                </div>
                <div className="d-flex justify-content-evenly align-items-center">
                  <span className="ml-2">Teacher 1</span>
                  <button  className="btn btn-success-light" onClick={()=>setVisible(true)}>Mark present</button>
                </div>    
              </div>
            </div>
           
            


            <div className="card p-3 mobile-show">
                  <div className="d-flex flex-column gap-2">
                  <DatePicker
              selected={startDate}
              onChange={(update) => {
                setStartDate(update);
              }}
              isClearable={true}
              placeholderText="Select Date "
              dateFormat="dd-MM-yyyy"
            />

            <TimePicker
              className="form-control "
              onChange={(value) => setStartTime(value)}
              placeholder="Select Start Time"
              isClearable={true}
              format="HH:mm"
            />

            <TimePicker
              className="form-control "
              onChange={(value) => setEndTime(value)}
              placeholder="Select End Time"
              isClearable={true}
              format="HH:mm"
            />

            <select
              name=""
              className="form-control form-select"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              id=""
            >
              <option value="">Subject</option>
            </select>
                   </div>
              
                </div>


            <div className="card">
            
             
                
              <Table
          rowKey="_id"
          columns={columns}
          width={300}
          className="bordered-table width-box"
          // dataSource={userDetails}
          pagination={false}
        />
               
              
            </div>
         
          </div>
        </div>
       
      </>
    </div>
  );
};

export default AttendanceListingteacher;
